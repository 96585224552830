import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Nav from "./Nav";

const Layout = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Mobile Menu Icon */}
      <div
        className="sm:hidden absolute top-4 left-4 z-50 cursor-pointer p-2 bg-gray-800 rounded-md block"
        onClick={() => setIsNavOpen(!isNavOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>

      {/* Navigation Sidebar */}
      <div
        className={`${
          isNavOpen ? "fixed inset-0 z-40 bg-black text-white" : "hidden"
        } sm:block sm:w-2/12`}
      >
        <Nav />
      </div>

      {/* Main Content Area */}
      <div className="flex-grow overflow-y-auto w-full sm:w-10/12">
        {children}
      </div>
    </div>
  );
};

export default Layout;
