import React, { useState } from "react";
import Layout from "./components/Layout";

const Contact = () => {
  const [status, setStatus] = useState("");
  const [popup, setPopup] = useState(false);
  const [msg, setMsg] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateContact = (contact) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

    return emailPattern.test(contact) || phonePattern.test(contact);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateContact(formData.contact)) {
      setStatus("not sent");
      setMsg("Please enter a valid email or phone number");
      setPopup(true);
      setTimeout(() => {
        setPopup(false);
      }, 6000);
      return;
    }

    const response = await fetch("https://formspree.io/f/xqazrwal", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setStatus("sent");
      setMsg("Your message has been sent!");
      setFormData({ name: "", contact: "", message: "" });
    } else {
      setStatus("not sent");
      setMsg("Error processing your message try calling us!");
    }
    setPopup(true);

    setTimeout(() => {
      setPopup(false);
    }, 6000);
  };

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#f20404] h-screen">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">
            Contact Us
          </h1>

          <p className="text-center text-gray-600 mb-1">
            <strong>Phone:</strong> <span>403-948-1048</span>
          </p>
          <p className="text-center text-gray-600 mb-1">
            <strong>Email:</strong> <span>deepdishairdrie@gmail.com</span>
          </p>
          <p className="text-center text-gray-600 mb-4">
            {" "}
            <strong>Address:</strong>{" "}
            <span>2002 Luxetone BLVD SW Unit 108, Airdrie, AB T4B2W6</span>
          </p>

          <form
            action="https://formspree.io/f/xqazrwal"
            method="POST"
            onSubmit={handleSubmit}
            className="space-y-6"
          >
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 focus:outline-none focus:ring focus:ring-opacity-40"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email or Phone Number
              </label>
              <input
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 focus:outline-none focus:ring focus:ring-opacity-40"
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 focus:outline-none focus:ring focus:ring-opacity-40"
                rows="5"
                required
              ></textarea>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Popup Modal */}
      {popup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 transition-opacity duration-300">
          <div className="bg-white max-w-xs w-full p-4 rounded-lg shadow-lg text-center transition-transform transform scale-100 sm:max-w-sm">
            <h2
              className={`text-lg font-bold mb-4 ${status === "sent" ? "text-green-500" : "text-red-500"}`}
            >
              {status === "sent" ? "Success" : "Error"}
            </h2>
            <p className="text-gray-700 mb-4 text-sm">{msg}</p>
            <div className="flex justify-center">
              <button
                onClick={() => setPopup(false)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Contact;
