// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./HomeScreen";
import Hours from "./Hours";
import Merch from "./Merch";
import About from "./About";
import Contact from "./Contact";
import Menu from "./Menu";
import Order from "./Order";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/Order" element={<Order />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/Hours" element={<Hours />} />
          <Route path="/Merch" element={<Merch />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
