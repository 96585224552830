import React from "react";
import Layout from "./components/Layout";

const About = () => {
  return (
    <Layout>
      <div className="p-8 bg-gray-100">
        <h1 className="text-5xl font-bold text-center mb-6 mt-4">
          Welcome to Chicago Deep Dish Airdrie!
        </h1>
        <p className="text-xl text-center mb-6">
          We are passionate about providing delicious, high-quality pizza to our
          customers. Our team takes great pride in using only the freshest
          ingredients and making our pizza from scratch every day. We believe
          that pizza can be more than just a quick meal. It's an experience that
          you enjoy with friends and family. Whether you're in the mood for a
          classic cheese pizza or something a little more adventurous, we've got
          you covered. Our menu features a wide variety of toppings so you can
          customize your pizza to your exact specifications. We are committed to
          providing exceptional service and bringing happiness to the community
          one meal at a time.
        </p>

        {/* Video Section */}
        <div className="flex justify-center mb-8">
          <div className="w-full md:w-2/3 lg:w-1/2">
            <div
              className="relative"
              style={{ paddingBottom: "56.25%", height: 0 }}
            >
              <iframe
                src="https://www.youtube.com/embed/your-video-id"
                title="Chicago Deep Dish Airdrie Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Image Gallery */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <img
            src={"finalEdits/1.jpg"}
            alt={"Pizza Image 1"}
            className="w-full h-auto object-cover rounded-lg"
          />
          <img
            src={"finalEdits/2.jpg"}
            alt={"Pizza Image 2"}
            className="w-full h-auto object-cover rounded-lg"
          />
          <img
            src={"finalEdits/3.jpg"}
            alt={"Pizza Image 3"}
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
      </div>
    </Layout>
  );
};

export default About;
