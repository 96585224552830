import React from "react";
import { Link, useLocation } from "react-router-dom";

const Nav = () => {
  const location = useLocation();

  const handleLinkClick = (path) => {
    if (location.pathname === path) {
      window.location.href = path;
    }
  };

  return (
    <div className="h-full p-8 bg-black text-white flex flex-col overflow-auto justify-between">
      <div
        className="text-center cursor-pointer"
        onClick={() => (window.location.href = "/")}
      >
        <img src="dd1.jpg" alt="logo" className="h-1/2 w-full mt-4 sm:mt-4" />{" "}
        <hr className="border-gray-600 " />
        <p className="text-md mt-2 sm:mt-4 sm:text-base ">
          2002 Luxstone Blvd SW Unit 108, Airdrie, AB T4B 2W6
        </p>{" "}
        <hr className="border-gray-600 mt-2" />
        <p className="font-semibold text-[#FFFF00] text-md mt-2 sm:mt-1">
          Airdries Favorite Pizza Joint 🍕
        </p>{" "}
      </div>
      <div className="mt-6 mb-auto  sm:mt-8">
        <nav className="text-lg font-bold text-center sm:mt-4 space-y-4 sm:space-y-4">
          <a
            href="tel:+14039481048"
            className="bg-white text-gray-900 rounded-md font-bold py-1 px-1 mt-6 mb-6 w-full block text-center sm:mt-4 sm:py-2 sm:px-3 hover:bg-red-600"
          >
            <p>Click here to call us</p>
            <p> (403) 948-1048 </p>{" "}
          </a>
          <Link
            to="/Menu"
            onClick={() => handleLinkClick("/Menu")}
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Menu"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            MENU
          </Link>
          <Link
            to="/Hours"
            onClick={() => handleLinkClick("/Hours")}
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Hours"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            HOURS & LOCATION
          </Link>
          <Link
            to="/Merch"
            onClick={() => handleLinkClick("/Merch")}
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Merch"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            MERCH
          </Link>
          <Link
            to="/About"
            onClick={() => handleLinkClick("/About")}
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/About"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            ABOUT
          </Link>
          <Link
            to="/Contact"
            onClick={() => handleLinkClick("/Contact")}
            className={`block border-b border-gray-700 pb-2 ${
              location.pathname === "/Contact"
                ? "text-red-600"
                : "hover:text-red-600"
            } sm:pb-3`}
          >
            CONTACT
          </Link>
        </nav>
      </div>
      <div className="flex justify-center mt-2 space-x-4">
        <a href="https://www.facebook.com/people/Chicago-Deep-Dish-Airdrie/61567247372573/">
          <i className="fab fa-facebook-f text-white"></i>
        </a>
        <a href="https://www.instagram.com/chicagodeepdishairdrie/">
          <i className="fab fa-instagram text-white"></i>
        </a>
        <Link to="/Contact" onClick={() => handleLinkClick("/Contact")}>
          <i className="fas fa-envelope text-white"></i>
        </Link>
      </div>
    </div>
  );
};

export default Nav;
