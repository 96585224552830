import React from "react";
import Layout from "./components/Layout";

const Menu = () => {
  return (
    <Layout>
      <div className="relative flex-grow min-h-screen overflow-auto">
        <h1 className="text-4xl font-bold text-center mb-4">Our Menu</h1>

        {/* Menu1 Section */}
        <div className="w-full h-auto">
          <img
            src={"menu1.png"}
            alt={"menu1"}
            className="w-full h-auto object-cover"
          />
        </div>

        {/* Menu2 Section*/}
        <div className="flex flex-col md:flex-row justify-between">
          <section className="w-full md:w-1/2 p-4">
            <h2 className="text-4xl text-center font-bold mt-2 mb-2">
              Our Sides
            </h2>
            <img
              src={"menu2.png"}
              alt={"menu2"}
              className="w-full h-auto object-contain"
            />
          </section>
          <section className="w-full md:w-1/2 p-4">
            <div className="h-12"></div>
            <img
              src={"finalEdits/71.jpg"}
              alt={"menu3"}
              className="w-full h-auto object-contain"
            />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Menu;
