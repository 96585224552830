import React, { useState, useEffect, useMemo } from "react";
import Layout from "./components/Layout";

// Preload function to ensure the images are loaded
const preloadImages = (srcArray) => {
  srcArray.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

const Modal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-black p-8 rounded-lg shadow-lg text-center max-w-sm mx-auto">
        <img src="dd1.jpg" alt="logo" />
        <h2 className="text-2xl text-white font-bold mb-4">Welcome!</h2>
        <p className="text-white mb-4">
          Support us by picking up your order instead of opting for delivery.
          Not only will you get to enjoy your item sooner, but you’ll also save
          on delivery costs while helping us thrive and continue to offer
          high-quality food at affordable prices. We appreciate your valuable
          support and look forward to serving you soon!
        </p>
        <button
          onClick={onClose}
          className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const HomeScreen = () => {
  const images = useMemo(
    () => [
      "/finalEdits/11.jpg",
      "/finalEdits/2.jpg",
      "/finalEdits/13.jpg",
      "/finalEdits/54.jpg",
      "/finalEdits/57.jpg",
      "/finalEdits/15.jpg",
      "/finalEdits/39.jpg",
      "/finalEdits/10.jpg",
      "/finalEdits/7.jpg",
      "/finalEdits/41.jpg",
      "/finalEdits/56.jpg",
      "/finalEdits/74.jpg",
    ],
    [],
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    preloadImages(images);
  }, [images]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout>
      {isModalVisible && <Modal onClose={handleCloseModal} />}
      <div className="relative overflow-hidden">
        <div className="flex" style={{ width: "300%" }}>
          {/* Slide Previous Image */}
          <img
            src={images[(currentIndex - 1 + images.length) % images.length]}
            alt="Previous Slide"
            className="w-full h-screen object-cover"
          />
          {/* Slide Current Image */}
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className="w-full h-screen object-cover"
          />
          {/* Slide Next Image */}
          <img
            src={images[(currentIndex + 1) % images.length]}
            alt="Next Slide"
            className="w-full h-screen object-cover"
          />
        </div>

        <div className="absolute inset-0 flex justify-between items-center">
          {/* Left Arrow */}
          <button
            onClick={handlePrev}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-4 rounded-full hover:bg-opacity-75 focus:outline-none"
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          {/* Right Arrow */}
          <button
            onClick={handleNext}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-4 rounded-full hover:bg-opacity-75 focus:outline-none"
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <div className="absolute bottom-0 left-0 w-full p-8 text-center text-white bg-black bg-opacity-50">
          <p>@CDDP_Pizza</p>
        </div>
      </div>
    </Layout>
  );
};

export default HomeScreen;
