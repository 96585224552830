import React from "react";
import Layout from "./components/Layout";

const Merch = () => {
  return (
    <div className="bg-[#f20404] h-screen">
      <Layout>
        <div className="flex items-center text-center justify-center h-full">
          <h1 className="font-bold text-7xl">Coming Soon!</h1>
        </div>
      </Layout>
    </div>
  );
};

export default Merch;
