import React from "react";
import Layout from "./components/Layout";

const Order = () => {
  return (
    <div className="bg-[#f20404] h-screen">
      <Layout>
        <div className="flex flex-col items-center justify-center h-full mt-13">
          <p className="text-center text-xl font-semibold mb-4 mt-10">
            Pizza by the Slice. Enjoy a quick and tasty slice of our most
            popular pizzas, perfect for lunch or a snack! Choose from a rotating
            selection of our favorites, available every day. Check in-store for
            today’s slice options.
          </p>
          <img
            src="slice.jpg"
            alt="Pizza Slice"
            className="w-80 h-80 sm:w-30 sm:h-1/2"
          />
        </div>
      </Layout>
    </div>
  );
};
export default Order;
